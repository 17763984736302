import {
  userTransactionSelector,
  getUserTransactions,
} from "../../../../Redux/Features/Machines/UserTransaction";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import UserStatsLoader from "../Components/UserStatsLoader";
import { CSVLink } from "react-csv";
import { addCommasToNumber, decryptObject, machineUserTransactions } from "Constant/Constant";
import moment from 'moment-timezone';


function UserTransactions() {
  const dispatch = useDispatch<any>();
  let { id } = useParams();

   //localStorage
   const authData = localStorage.getItem("auth");
   const localStorageDataDecrypted = JSON.parse(authData);
   const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

   console.log("testing",localStorageData);

  const { userTransactionsData, userTransactionsLoader, userTransactionError } =
    useSelector(userTransactionSelector);

  useEffect(() => {
    dispatch(getUserTransactions(id));
  }, [dispatch, id]);

  return (
    <div className="">
      <div className="sm:flex sm:items-center mt-4 justify-center">
        <div className="sm:flex-auto">
          <h1 className="py-5 text-gray-900 dark:!text-gray-500 text-4xl font-extrabold leading-7">User transactions</h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <CSVLink data={userTransactionsData} headers={machineUserTransactions} className="block rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">
            Export as csv
          </CSVLink>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="ring-black overflow-hidden shadow ring-1 ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50 dark:!bg-gray-900">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:!text-white  sm:pl-6"
                    >
                      SNO
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:!text-white  sm:pl-6"
                    >
                      {localStorageData.user.first_name === "National" || localStorageData.user.first_name === "English" ? "Employee id" : "Phone"}
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:!text-white  sm:pl-6"
                    >
                      Product
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white "
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white "
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white "
                    >
                      Quantity
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:!text-white "
                    >
                      Created at
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {userTransactionsLoader ?
                    <UserStatsLoader />
                    :
                    userTransactionsData
                    .map((trans: any, index: any) => {
                      console.log("test", trans);
                      return (
                        <tr key={trans.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-6">
                            {index + 1}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-6">
                            {trans.msisdn}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:!text-gray-500 sm:pl-6">
                            {trans.brand_name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500">
                            {addCommasToNumber(Math.floor(trans.amount))}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500">
                            {trans.price}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500">
                            {trans.quantity}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:!text-gray-500">
                            {moment(trans.created_at).tz('Etc/GMT-0').format("llll")}
                            {/* {trans.created_at} */}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default UserTransactions;
