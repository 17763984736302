import React, { HTMLProps, useState } from 'react'
import {
    Column,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    Table,
    useReactTable,
} from '@tanstack/react-table'
import JpCoatsModal from './JpCoatsModal';
import IbexLoader from '../Components/IbexLoader';
import moment from 'moment-timezone';
import { decryptObject } from 'Constant/Constant';

// WAWs0NQY3m67kVnO4aw6cs9O5XEpVfJh
// lnTo1wTIqd2XFTSKcGsmiMg1YZOEycaL	

export default function JpCoatsTenStackTable(props: { ibexUserLists: any; ibexUserLoader: any; getIbexList: any }) {
    //localStorage
    const authData = localStorage.getItem("auth");
    // const localStorageData = JSON.parse(authData);
    const localStorageDataDecrypted = JSON.parse(authData);
    const localStorageData = decryptObject(localStorageDataDecrypted.encryptedUser);

    const { ibexUserLists, ibexUserLoader, getIbexList } = props;

    const [rowSelection, setRowSelection] = useState({});
    const [open, setOpen] = useState(false);

    const columns = React.useMemo(
        () => [
            {
                id: 'select',
                header: ({ table }: any) => (
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                ),
                cell: ({ row }: any) => (
                    <div className="px-1">
                        <IndeterminateCheckbox
                            {...{
                                checked: row.getIsSelected(),
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler(),
                            }}
                        />
                    </div>
                ),
            },
            {
                accessorKey: "name",
                header: () => <span>Name</span>,
                footer: (props: any) => props.column.id,
            },
            {
                accessorFn: (row: any) => row.card_number || row.mobile_number,
                id: "card_number",
                header: () => <span>Card Number / Mobile Number</span>,
                footer: (props: any) => props.column.id,
                cell: (info: any) => info.getValue(),
            },
            {
                accessorFn: (row: any) => moment(row.created_at).tz('Etc/GMT-0').format('llll'),
                id: "created_at",
                header: () => <span>Created at</span>,
                footer: (props: any) => props.column.id,
                cell: (info: any) => info.getValue(),
            },
            {
                accessorKey: "balance",
                header: () => <span>Balance</span>,
                footer: (props: any) => props.column.id,
            },
        ],
        []
    )

    const data = ibexUserLists;

    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection,
        },
        enableRowSelection: true, //enable row selection for all rows
        // enableRowSelection: row => row.original.age > 18, // or enable row selection conditionally per row
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        debugTable: true,
    })

    const handleTopup = () => {
        if (localStorageData.user.user_role === "ops") {
            alert("You dont have access");
        } else {
            setOpen(!open);
        }
    }


    return (
        <>
            {
                ibexUserLoader ?
                    <IbexLoader /> :
                    <div className="mt-8 flow-root pb-5">
                        {
                            Object.keys(rowSelection).length >= 1 ?
                                <div className='flex py-1 items-center'>
                                    <span>
                                        {Object.keys(rowSelection).length} of{' '}
                                        {table.getPreFilteredRowModel().rows.length} Total Rows Selected
                                    </span>
                                    <span>
                                        <button
                                            onClick={handleTopup}
                                            className='mx-2 rounded bg-teal-500 px-2 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-teal-600'>
                                            Add Topup
                                        </button>
                                    </span>
                                </div> : null
                        }

                        <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                            <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                                <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50 dark:!bg-gray-900 ">
                                            {table.getHeaderGroups().map(headerGroup => (
                                                <tr key={headerGroup.id}>
                                                    {headerGroup.headers.map(header => {
                                                        return (
                                                            <th key={header.id} colSpan={header.colSpan} className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 dark:!text-white">
                                                                {header.isPlaceholder ? null : (
                                                                    <>
                                                                        {flexRender(
                                                                            header.column.columnDef.header,
                                                                            header.getContext()
                                                                        )}
                                                                        {header.column.getCanFilter() ? (
                                                                            <div>
                                                                                <Filter column={header.column} table={table} />
                                                                            </div>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </th>
                                                        )
                                                    })}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white dark:!bg-gray-900">
                                            {table.getRowModel().rows.map(row => {
                                                return (
                                                    <tr key={row.id}>
                                                        {row.getVisibleCells().map(cell => {
                                                            return (
                                                                <td key={cell.id} className="whitespace-nowrap dark:!text-gray-500 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                    {flexRender(
                                                                        cell.column.columnDef.cell,
                                                                        cell.getContext()
                                                                    )}
                                                                </td>
                                                            )
                                                        })}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td className="pl-7 py-1">
                                                    <IndeterminateCheckbox
                                                        {...{
                                                            checked: table.getIsAllPageRowsSelected(),
                                                            indeterminate: table.getIsSomePageRowsSelected(),
                                                            onChange: table.getToggleAllPageRowsSelectedHandler(),
                                                        }}
                                                    />
                                                </td>
                                                <td colSpan={20} className='dark:!text-white'>Page Rows ({table.getRowModel().rows.length})</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="h-2" />
                        <div className="w-100 flex items-center justify-between">
                            {/* first div */}
                            <div className="flex">
                                <button
                                    className="relative mr-2 inline-flex dark:!bg-gray-900 dark:!text-white items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                                    onClick={() => table.setPageIndex(0)}
                                    disabled={!table.getCanPreviousPage()}
                                >
                                    {"<<"}
                                </button>
                                <button
                                    className="relative inline-flex items-center rounded-md dark:!bg-gray-900 dark:!text-white bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                                    onClick={() => table.previousPage()}
                                    disabled={!table.getCanPreviousPage()}
                                >
                                    {"<"}
                                </button>
                                <button
                                    className="relative ml-2 inline-flex items-center rounded-md dark:!bg-gray-900 dark:!text-white bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                                    onClick={() => table.nextPage()}
                                    disabled={!table.getCanNextPage()}
                                >
                                    {">"}
                                </button>
                                <button
                                    className="relative ml-2 inline-flex items-center rounded-md dark:!bg-gray-900 dark:!text-white bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                    disabled={!table.getCanNextPage()}
                                >
                                    {">>"}
                                </button>
                                <span className="ml-2 flex items-center gap-1 dark:!text-white">
                                    <div className='dark:!text-white'>Page</div>
                                    <strong className='dark:!text-white'>
                                        {table.getState().pagination.pageIndex + 1} of{" "}
                                        {table.getPageCount()}
                                    </strong>
                                </span>
                            </div>

                            {/* second div */}
                            <div className="flex">
                                <span className="mr-2 flex items-center gap-1 dark:!text-white">
                                    Go to page:
                                    <input
                                        type="number"
                                        defaultValue={table.getState().pagination.pageIndex + 1}
                                        onChange={(e) => {
                                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                            table.setPageIndex(page);
                                        }}
                                        className="block rounded-md border-0 px-2 py-1.5 dark:!bg-gray-900 dark:!text-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </span>
                                <select
                                    value={table.getState().pagination.pageSize}
                                    onChange={(e) => {
                                        table.setPageSize(Number(e.target.value));
                                    }}
                                    className="block rounded-md dark:!bg-gray-900 dark:!text-white border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                    {[10, 20, 30, 40, 50].map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            Show {pageSize}
                                        </option>
                                    ))}
                                </select>
                                <JpCoatsModal open={open} setOpen={setOpen} selectedCard={table.getSelectedRowModel().flatRows} getIbexList={getIbexList} />
                            </div>
                        </div>
                    </div>
            }
        </>

    )
}

function Filter({
    column,
    table,
}: {
    column: Column<any, any>
    table: Table<any>
}) {
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id)

    return typeof firstValue === 'number' ? (
        <div className="flex space-x-2">
            <input
                type="number"
                value={((column.getFilterValue() as any)?.[0] ?? '') as string}
                onChange={e =>
                    column.setFilterValue((old: any) => [e.target.value, old?.[1]])
                }
                placeholder={`Min`}
                className="block rounded-md border-0 px-2 py-1.5 dark:!bg-gray-900 dark:!text-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

            />
            <input
                type="number"
                value={((column.getFilterValue() as any)?.[1] ?? '') as string}
                onChange={e =>
                    column.setFilterValue((old: any) => [old?.[0], e.target.value])
                }
                placeholder={`Max`}
                className="block rounded-md border-0 px-2 py-1.5 dark:!bg-gray-900 dark:!text-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

            />
        </div>
    ) : (
        <input
            type="text"
            value={(column.getFilterValue() ?? '') as string}
            onChange={e => column.setFilterValue(e.target.value)}
            placeholder={`Search...`}
            className="block rounded-md border-0 px-2 py-1.5 dark:!bg-gray-900 dark:!text-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

        />
    )
}

function IndeterminateCheckbox({
    indeterminate,
    className = '',
    ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!)

    React.useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate
        }
    }, [ref, indeterminate])

    return (
        <input
            type="checkbox"
            ref={ref}
            className={className + ' cursor-pointer'}
            {...rest}
        />
    )
}