import { BASE_URL_TWO } from 'Constant/Constant';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';

const SendNotification = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    
    const [userStaticsLoader, setUserStaticsLoader] = useState(true);
    const [userStatsData, setUserStatsData] = useState([]);
    const [notificationNumbers, setNotificationNumbers] = useState([]);
    const [allSelected, setAllSelected] = useState(false);

    useEffect(() => {
        const apiUrl = `${BASE_URL_TWO}getPhoneNumberWithFcm`;

        axios.get(apiUrl, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
        .then(response => {
            const users = response.data.data.usersWithFcmAndToken.map((e: any) => ({
                label: `${e.mobile_number} (${e.name})`,
                value: e.mobile_number
            }));

            setUserStatsData(users);
            setUserStaticsLoader(false);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            setUserStaticsLoader(false);
        });
    }, []);

    // Handle select change
    const handleSelectChange = (selectedOptions: any) => {
        setNotificationNumbers(selectedOptions || []);
        setAllSelected(selectedOptions.length === userStatsData.length);
    };

    // Handle "Select All" button click
    const handleSelectAll = () => {
        if (allSelected) {
            setNotificationNumbers([]);
        } else {
            setNotificationNumbers(userStatsData);
        }
        setAllSelected(!allSelected);
    };

    // Custom MenuList to add "Select All" button
    const CustomMenuList = (props: any) => {
        return (
            <components.MenuList {...props}>
                <div
                    onClick={handleSelectAll}
                    style={{
                        padding: "10px",
                        cursor: "pointer",
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: allSelected ? "red" : "teal",
                        textAlign: "center",
                        borderRadius: "5px",
                        margin: "5px",
                    }}
                >
                    {allSelected ? "Deselect All" : "Select All"}
                </div>
                {props.children}
            </components.MenuList>
        );
    };

    const handleNotification = (data: any) => {
        axios.post(`${BASE_URL_TWO}sentMultiNotification`, {
            multiNumber: notificationNumbers.map((e: any) => e.value),
            title: data.title,
            body: data.body
        })
        .then(() => {
            toast.success("Notification sent successfully!");
        })
        .catch((err: any) => {
            toast.error(err.message);
        });
    };

    return (
        <form className='py-4 w-2/3' onSubmit={handleSubmit(handleNotification)}>
            <div className='m-2'>
                <label>Select Numbers</label>
                {userStaticsLoader ? (
                    <h1>Loading...</h1>
                ) : (
                    <Select
                        isMulti
                        name="numbers"
                        options={userStatsData}
                        value={notificationNumbers}
                        onChange={handleSelectChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        components={{ MenuList: CustomMenuList }} // Use custom dropdown
                    />
                )}
            </div>
            <div className='m-2'>
                <label>Title</label>
                <input
                    {...register("title", { required: true })}
                    className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 shadow-sm ring-1 ring-inset ring-teal-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-500 sm:text-sm sm:leading-6"
                />
                {errors.title && <span className="text-red-500">This field is required</span>}
            </div>
            <div className='m-2'>
                <label>Body</label>
                <input
                    {...register("body", { required: true })}
                    className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 shadow-sm ring-1 ring-inset ring-teal-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-500 sm:text-sm sm:leading-6"
                />
                {errors.body && <span className="text-red-500">This field is required</span>}
            </div>
            <div className='m-2'>
                <input
                    className="mt-5 inline-flex w-full cursor-pointer items-center gap-x-2 rounded-md bg-teal-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    value="Send Notification"
                    type="submit"
                />
            </div>
        </form>
    );
};

export default SendNotification;
